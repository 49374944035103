import React, { Suspense, lazy } from 'react';

import { Navigate, Route, Routes as BrowserRoutes } from 'react-router-dom';
import useAuthContext from './hooks/UseAuthContext';
import Loading from './components/loading/Loading';

const Home = lazy(() => import('./pages/home/Home'));
const LeafletMap = lazy(() => import('./components/leaflet-map/LeafletMap'));
const NotFound = lazy(() => import('./pages/not-found/NotFound'));
const Login = lazy(() => import('./pages/login/Login'));
const Register = lazy(() => import('./pages/register/Register'));
const VerifyEmail = lazy(() => import('./pages/verify-email/VerifyEmail'));
const CurlConverter = lazy(
  () => import('./components/curl-convertor/CurlConvertor')
);
const UpdatePassword = lazy(
  () => import('./pages/update-password/UpdatePassword')
);
const ForgotPassword = lazy(
  () => import('./pages/forgot-password/ForgotPassword')
);
const ResetPassword = lazy(
  () => import('./pages/reset-password/ResetPassword')
);
const Nwp = lazy(() => import('./pages/nwp/Nwp'));

const NwpPrimaryInfo = lazy(
  () => import('./pages/nwp/nwp-primary-info/NwpPrimaryInfo')
);
const NwpForecasts = lazy(
  () => import('./pages/nwp/nwp-forecasts/NwpForecasts')
);
const NwpSelectParameters = lazy(
  () => import('./pages/nwp/nwp-select-parameters/NwpSelectParameters')
);
const NwpDownload = lazy(() => import('./pages/nwp/nwp-download/NwpDownload'));
const Cmd = lazy(() => import('./pages/cmd/Cmd'));
const CmdHome = lazy(() => import('./pages/cmd/cmd-home/CmdHome'));
const CmdSingleDataset = lazy(
  () => import('./pages/cmd/cmd-single-dataset/CmdSingleDataset')
);
const CmdRequestProcessing = lazy(
  () => import('./pages/cmd/cmd-request-processing/CmdRequestProcessing')
);
const CmdReviewInformation = lazy(
  () =>
    import(
      './pages/cmd/cmd-download-form/cmd-form/cmd-review-information/CmdReviewInformation'
    )
);
const Radar = lazy(() => import('./pages/radar/Radar'));
const RadarDataArchive = lazy(
  () => import('./pages/radar/radar-data-archive/RadarDataArchive')
);
const YourRequests = lazy(() => import('./pages/your-requests/YourRequests'));
const RadarNearRealTime = lazy(
  () => import('./pages/radar/radar-near-real-time/RadarNearRealTime')
);
const RadarDataArchiveRequestProcessing = lazy(
  () =>
    import(
      './pages/radar/radar-data-archive/radar-data-archive-request-processing/RadarDataArchiveRequestProcessing'
    )
);
const NwpRequestProcessing = lazy(
  () => import('./pages/nwp/nwp-request-processing/NwpRequestProcessing')
);
const NwpNearRealTime = lazy(
  () => import('./pages/nwp/nwp-near-real-time/NwpNearRealTime')
);
const Profile = lazy(() => import('./pages/profile/Profile'));
const GApi = lazy(() => import('./components/generate-api/GenererateApi'));

interface IRoutesProps {}

const Routes: React.FC<IRoutesProps> = () => {
  const { isAuthenticated } = useAuthContext();
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRoutes>
        <Route
          path="/login"
          element={!isAuthenticated ? <Login /> : <Navigate to="/" replace />}
        />
        <Route
          path="/register"
          element={
            !isAuthenticated ? <Register /> : <Navigate to="/" replace />
          }
        />
        <Route path="/" element={<Home />} />
        <Route
          path="/forgot-password"
          element={!isAuthenticated ? <ForgotPassword /> : <NotFound />}
        />
        <Route
          path="/reset-password"
          element={!isAuthenticated ? <ResetPassword /> : <NotFound />}
        />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/nwp" element={<Nwp />}>
          <Route path="/nwp/primary-info" element={<NwpPrimaryInfo />} />
          <Route path="/nwp/forecast" element={<NwpForecasts />} />
          <Route path="/nwp/parameters" element={<NwpSelectParameters />} />
          <Route path="/nwp/download" element={<NwpDownload />} />
          <Route
            path="/nwp/data-store/:requestId"
            element={<NwpRequestProcessing />}
          />
        </Route>

        <Route
          path="/nwp"
          element={
            isAuthenticated ? <LeafletMap /> : <Navigate to="/login" replace />
          }
        />

        <Route path="/nwp/near-realtime" element={<NwpNearRealTime />} />

        <Route path="/cmd" element={<Cmd />}>
          <Route index element={<CmdHome />} />
          <Route path=":id/overview" element={<CmdSingleDataset />} />
          <Route path=":id/download-data" element={<CmdSingleDataset />} />
          <Route path=":id/documentation" element={<CmdSingleDataset />} />
          <Route path=":id/description" element={<CmdSingleDataset />} />
          <Route path=":id/:requestId" element={<CmdRequestProcessing />} />
          <Route
            path=":id/review"
            element={
              isAuthenticated ? (
                <CmdReviewInformation />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Route>
        <Route path="/radar" element={<Radar />}>
          <Route path="data-store" element={<RadarDataArchive />} />
          <Route
            path="data-store/:requestId"
            element={<RadarDataArchiveRequestProcessing />}
          />
          <Route path="near-realtime" element={<RadarNearRealTime />} />
        </Route>
        <Route
          path="/your-requests"
          element={isAuthenticated ? <YourRequests /> : <Login />}
        />
        <Route
          path="/curl"
          element={true ? <CurlConverter /> : <Navigate to="/" replace />}
        />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route
          path="/profile"
          element={
            isAuthenticated ? <Profile /> : <Navigate to="/login" replace />
          }
        />
        <Route path="gapi" element={<GApi />} />
        <Route path="*" element={<NotFound />} />
      </BrowserRoutes>
    </Suspense>
  );
};

export default Routes;
